import React from "react";
import { Modal } from "react-bootstrap";
import { ReleaseNotesWrapper } from "./style";
import UIModal from "../UIModal";

const ReleaseNotes = ({ showModal, setShowModal, size = "lg", content }) => {
    return (
        <UIModal
            show={showModal}
            onHide={() => { setShowModal(false) }}
            size={size}
            header={<Modal.Title
                className='text-center'
                id="example-custom-modal-styling-title">
                <ReleaseNotesWrapper>
                    <div className='termsNconditions-label'>{content?.main_heading?.title}</div>
                </ReleaseNotesWrapper>
            </Modal.Title>}
        >
            <ReleaseNotesWrapper>
                <div className='contents'>
                    <ReleaseNotesWrapper>
                        {content?.preface?.map((item, index) => {
                            return (
                                <div className='preface-content' key={index}> {item} </div>
                            )
                        }
                        )}
                    </ReleaseNotesWrapper>
                    {content?.notes?.map((releaseNote, index) => {
                        return (
                            <div className='mt-2' key={index}>
                                <div className="title">
                                    {releaseNote.title}
                                </div>

                                {typeof (releaseNote.note) === "string" ? releaseNote.note :
                                    <ul>
                                        {releaseNote.note.map((note, index) => {
                                            return (
                                                <li className={note.class} key={index}>
                                                    {note.content}
                                                </li>
                                            )
                                        }
                                        )}
                                    </ul>
                                }
                            </div>
                        )
                    })}
                </div>
                <div className="contents"><div dangerouslySetInnerHTML={{ __html: content?.customer_support }} /></div>
            </ReleaseNotesWrapper>
        </UIModal>
    );
};

export default ReleaseNotes;