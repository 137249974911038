import React from 'react'
import styled from 'styled-components'
import AuctionHistoryCard from '../Reuseable/AuctionHistory'
import { useFetch } from '../../Hooks/useFetch'
import NoAuctionRunning from '../Reuseable/ErrorPage/NoAuctionRunning'
import { Container } from 'react-bootstrap'

const LiveAuctionHistoryWrap = styled.div`

`

const LiveAuctionHistory = ({ isLiveEnded }) => {

    const { data: liveAuctionHistory, isError } = useFetch("getLiveAuctionHistory", "/bid-leads/recent-auction", () => { }, () => { }, false, isLiveEnded)

    return (
        <LiveAuctionHistoryWrap>

            {liveAuctionHistory === undefined || isError ? <NoAuctionRunning needImage={true} /> :
                <>
                    <NoAuctionRunning needImage={false} />
                    <Container className='d-flex-row justify-content-center align-items-center mt-3 mb-4'>
                        <div className='ps-2 ms-1 mb-3 text-left fw-bold fs-5'>Previous auction results:</div>

                        {liveAuctionHistory?.map((oneData) => {
                            return (
                                <div key={oneData.application_id}>
                                    <AuctionHistoryCard won={oneData.you_won} data={oneData} />
                                </div>
                            )
                        })}
                    </Container>
                </>
            }

        </LiveAuctionHistoryWrap>
    )
}

export default LiveAuctionHistory