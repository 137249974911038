import 'bootstrap/dist/css/bootstrap.css';
import React from "react";
import { Form } from "react-bootstrap";
import Offcanvas from 'react-bootstrap/Offcanvas';
import { Filterbox } from "./style";

const SideMenuBar = ({ active,
  path,
  handleCloseMenu,
  showResposiveSideBar,
  dashBoardFilters,
  setDashBoardFilters,
  loanTypesToFilter,
  statesToFilter,
  setLoanTypesToFilter,
  setStatesToFilter,
  setBidHistoryFilters,
  bidHistoryFilters,
  setBidFilters,
  bidFilters
}) => {

  const handleData = (e) => {
    e.preventDefault()
  }

  const DashboardView = (
    <React.Fragment>
      <div className="accordion-item border-0">
        <h4 className="accordion-header" id="panelsStayOpen-loancard">
          <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-loan" aria-expanded="true" aria-controls="panelsStayOpen-loan">
            Loan types
          </button>
        </h4>
        <div id="panelsStayOpen-loan" className="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-loancard">
          <div className="accordion-body1">
            <div className='accordion-body border-top-0'>
              <Form onSubmit={handleData}>

                {loanTypesToFilter.map((oneLoan, index) => {
                  return (
                    <Form.Group key={oneLoan.loanTypes + index} className="types" controlId={index}>
                      <Form.Check type="checkbox"
                        value={oneLoan.loanTypes}
                        label={oneLoan.loanTypes}
                        onChange={(e) => {
                          let isChecked = e.target.checked
                          setLoanTypesToFilter(loanTypesToFilter.map((anyOneLoan) => {
                            return (anyOneLoan.loanTypes === oneLoan.loanTypes) ? { ...oneLoan, isChecked: isChecked } : anyOneLoan
                          }))

                          isChecked ? setDashBoardFilters([...dashBoardFilters, e.target.value])
                            :
                            setDashBoardFilters(
                              dashBoardFilters.filter((oneValue) => {
                                return (oneValue !== e.target.value)
                              }))
                        }}
                        checked={oneLoan.isChecked === undefined ? false : oneLoan.isChecked}
                      />
                    </Form.Group>
                  )
                })}

              </Form>
            </div>
          </div>
        </div>
      </div>

      <div className="accordion-item">
        <h4 className="accordion-header" id="panelsStayOpen-headingOne">
          <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="true" aria-controls="panelsStayOpen-collapseOne">
            By states
          </button>
        </h4>
        <div id="panelsStayOpen-collapseTwo" className="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-headingOne">
          <div className="accordion-body1">
            <div className='accordion-body-content'>
              <Form className="checkbox-color" onSubmit={handleData}>

                {statesToFilter.map((oneState, index) => {
                  return (
                    <Form.Group className="types" controlId={index + loanTypesToFilter.length} keys={index + loanTypesToFilter.length}>
                      <Form.Check type="checkbox"
                        value={oneState.states}
                        label={oneState.states}
                        onChange={(e) => {
                          let isChecked = e.target.checked

                          setStatesToFilter(statesToFilter.map((anyOneState) => {
                            return (anyOneState.states === oneState.states) ? { ...oneState, isChecked: isChecked } : anyOneState
                          }))

                          isChecked ? setDashBoardFilters([...dashBoardFilters, e.target.value])
                            :
                            setDashBoardFilters(
                              dashBoardFilters.filter((oneValue) => {
                                return (oneValue !== e.target.value)
                              }))
                        }}
                        checked={oneState.isChecked === undefined ? false : oneState.isChecked}
                      />
                    </Form.Group>
                  )
                })}
              </Form>

            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )

  const BidHistoryView = (
    <div className="accordion-item">
      <div className="accordion-body1">
        <div className='accordion-body'>
          <Form onSubmit={handleData}>

            {bidFilters.map((oneFilter, index) => {
              return (
                <Form.Group className="types" key={index} controlId={index}>
                  <Form.Check type="checkbox"
                    label={(oneFilter.filter === "lost" ? "Out Bid" : (oneFilter.filter === "won" ? "Won" : "Ongoing"))}
                    value={oneFilter.filter}
                    onChange={(e) => {
                      let isChecked = e.target.checked

                      setBidFilters(bidFilters.map((anyOneFilter) => {
                        return (anyOneFilter.filter === oneFilter.filter) ? { ...oneFilter, isChecked: isChecked } : anyOneFilter
                      }))

                      isChecked ? setBidHistoryFilters([...bidHistoryFilters, e.target.value])
                        :
                        setBidHistoryFilters(
                          bidHistoryFilters.filter((oneValue) => {
                            return (oneValue !== e.target.value)
                          }))
                    }}
                    checked={oneFilter.isChecked} />
                </Form.Group>
              )
            })}

          </Form>
        </div>
      </div>
    </div>
  )

  return (
    <>
      {
        showResposiveSideBar ?
          <Offcanvas show={active} onHide={handleCloseMenu}>
            <Offcanvas.Header closeButton>
              <Filterbox>
                <Offcanvas.Title className='filter-N-refine'>Filter and Refine</Offcanvas.Title>
              </Filterbox>
            </Offcanvas.Header>
            <Offcanvas.Body className="p-0">
              <div>
                <Filterbox>
                  <div className="customise_sidebar">
                    {path === '/home/BidHistory' ? BidHistoryView : DashboardView}
                  </div>
                </Filterbox>
              </div>
            </Offcanvas.Body>
          </Offcanvas>
          :
          <Filterbox>
            <div className='filter-sidebar'>
              <div className={active ? 'active' : 'container'}>
                <div className="filter-box">
                  <div className="filter-box-header">
                    <h5 className='filter-N-refine'>Filter and Refine</h5>
                  </div>

                  {path === '/home/BidHistory' ? BidHistoryView : DashboardView}

                </div>
              </div>
            </div>
          </Filterbox>
      }
    </>

  );
};

export default SideMenuBar;