import React, { useState, useEffect} from "react";
import { Col, Container, Row } from "react-bootstrap";
import UICard from "../../../Reuseable/UICard";
import DashboardView from "./style";
import { useOutletContext } from "react-router-dom";
import Countdown from "react-countdown";
import { useDispatch } from "react-redux";
import LiveAuctionHistory from "../../../LiveAuctionHistory";
import Loading from "../../../Reuseable/Loading/Loading";
import { client } from "../../../../App";
import NoAuctionRunning from "../../../Reuseable/ErrorPage/NoAuctionRunning";
import { setShowNote } from "../../../../redux/Auth/actionCreator";

const Dashboard = () => {
  const dispatch = useDispatch()
  const [filteredCardDetails, setFilteredCardDetails] = useState([])
  
  let { dataFound,
    setDataFound,
    dashBoardFilters,
    setDashBoardFilters,
    getDashBoardData,
    profile, liveAuctionDetails, isLiveEnded, isLoading, firstCheck, refetch } = useOutletContext()
    
    useEffect(() => {
      setDashBoardFilters([])
      return () => {
        setHour(0)
      }
    }, [setDashBoardFilters])
    
  useEffect(() => {

    let filteredData = liveAuctionDetails?.lead?.filter(({ state, enquiry_type }) => {
      return dashBoardFilters.includes(state) || dashBoardFilters.includes(enquiry_type)
    })
    setFilteredCardDetails(filteredData?.length === 0 ? liveAuctionDetails.lead : filteredData)

  }, [liveAuctionDetails?.lead, dashBoardFilters, setFilteredCardDetails])

  useEffect(() => {

    const handleFilteringProcess = () => {
      let filteredData = liveAuctionDetails?.lead?.filter(({ state, enquiry_type }) => {
        return dashBoardFilters.includes(state) || dashBoardFilters.includes(enquiry_type)
      })
      setFilteredCardDetails(filteredData)
    }

    dashBoardFilters.length !== 0 ? handleFilteringProcess()
      :
      setFilteredCardDetails(liveAuctionDetails?.lead)

  }, [dashBoardFilters, liveAuctionDetails?.lead, setFilteredCardDetails])

  const doAfterCompletion = () => {
    isLiveEnded.current = true
    firstCheck.current = false
    client.setQueriesData(["dashBoardData"], [])
    getDashBoardData([], true)
    setFilteredCardDetails([])
    setDataFound(false)
    setShowNote(false)
  }

  const [hour, setHour] = useState(0)

  const hoursRenderer = ({ hours }) => {
    setHour(hours)
    return (
      hours
    )
  }

  const minutesRenderer = ({ minutes }) => {
    if (minutes <= 4) {
      dispatch(setShowNote(true))
    } else {
      dispatch(setShowNote(false))
    }
    return (
      minutes
    )
  }

  const secondsRenderer = ({ seconds }) => {
    return (
      seconds
    )
  }

  const isDataFound = () => {
    return dataFound && liveAuctionDetails !== "" ? true : false
  }

  if (isLoading) {
    return <>
      {firstCheck.current ? <Loading />
        :
        <LiveAuctionHistory isLiveEnded={isLiveEnded.current} />}
    </>
  }

  return (

    <DashboardView>
      {isDataFound() ? (
        isLoading ? isLiveEnded.current ? <NoAuctionRunning /> : <Loading /> :
          <Container fluid className='p-0'>
            <div className="pb-1 pt-2">
              <Row>
                <Col xs={12} sm={12} className='results-col-right'>
                  {
                    isDataFound() && (
                      <div className="d-flex flex-column flex-sm-row justify-content-between mx-1 mb-2 ">

                        <div>
                          {null}
                        </div>

                        <div>
                          <div className='live-auction-heading'> Hi {profile?.first_name}! </div>
                          <div className="fw-bold fs-5">Ready to start bidding?</div>
                        </div>

                        <div>
                          <div className="time-remaining">Time Remaining</div>
                          <p className="bid-credit">
                            <Row>
                              {
                                hour !== 0 && (
                                  <Col className="count-down-col" xs={5} sm={5} md={6}>
                                    <p className="minutes border rounded text-center box position-relative">
                                      <Countdown key={new Date(liveAuctionDetails?.bid_end_time)}
                                        date={new Date(liveAuctionDetails?.bid_end_time)} renderer={hoursRenderer} onComplete={doAfterCompletion} />
                                      <span className="min">hour</span>
                                      <span className="semi-colon">:</span>
                                    </p>
                                  </Col>
                                )
                              }

                              <Col className="count-down-col" xs={5} sm={5} md={6}>
                                <p className="minutes border rounded text-center box position-relative">
                                  <Countdown key={new Date(liveAuctionDetails?.bid_end_time)}
                                    date={new Date(liveAuctionDetails?.bid_end_time)} renderer={minutesRenderer} onComplete={doAfterCompletion} />
                                  <span className="min">min</span>
                                  <span className="semi-colon">:</span>
                                </p>
                              </Col>

                              <Col className="count-down-col" xs={5} sm={5} md={6}>
                                <p className="seconds border rounded text-center box">
                                  <Countdown key={new Date(liveAuctionDetails?.bid_end_time)}
                                    date={new Date(liveAuctionDetails?.bid_end_time)} renderer={secondsRenderer} />
                                  <span className="sec">sec</span>
                                </p>
                              </Col>
                            </Row>

                          </p>
                        </div>
                      </div>
                    )
                  }
                </Col>
              </Row>
            </div>
            {filteredCardDetails?.map((details) => {
              return (<UICard card={details} refetch={refetch} key={details.id} bidEndTime={liveAuctionDetails?.bid_end_time} filteredCardDetails={filteredCardDetails} />
              )
            })}
          </Container>
      )
        :
        <LiveAuctionHistory isLiveEnded={isLiveEnded.current} />}

    </DashboardView>

  );
}

export default React.memo(Dashboard)


