import axios from 'axios';

export const api = axios.create(
    {
        baseURL: 'https://uatapi.bidlead.com.au',
        headers: {
            "x-api-key": "fnn3TYLJlU1N1VpQv064FfJK5KWhe455tBNXyLyh",
        }
    }
);

export const lambdaUrl = "https://b522dxmi13.execute-api.ap-southeast-2.amazonaws.com/uat"
