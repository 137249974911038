import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";

const ProtectedRoute = () => {

  const isLoggedIn = useSelector((state) => state.Auth.isLoggedIn);
  const firstTimeLoginStatus = localStorage.getItem("isFirstTimeLoggin")

  return isLoggedIn ? (firstTimeLoginStatus === "false" || firstTimeLoginStatus === null || firstTimeLoginStatus === undefined) && <Outlet /> : <Navigate to="/login" />
}

export default ProtectedRoute;
