import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { ViewOnlyWrap } from "./style";
import Countdown from "react-countdown";
import { useDispatch } from "react-redux";
import Loading from "../../Reuseable/Loading/Loading";
import LiveAuctionHistory from "../../LiveAuctionHistory";
import NoAuctionRunning from "../../Reuseable/ErrorPage/NoAuctionRunning";
import { client } from "../../../App";
import { setShowNote } from "../../../redux/Auth/actionCreator";
import UICard from "../../Reuseable/UICard";
import { useRef } from "react";
import { useFetch } from "../../../Hooks/useFetch";
import BidLead from "../../../assets/images/icons/BidLead.svg";
import HeaderWrap from "../../Reuseable/UIHeader/style";
import { viewOnlyNote } from "../../../config/constant";

const Viewonly = () => {
    const dispatch = useDispatch()

    const firstCheck = useRef(true)
    const isLiveEnded = useRef(true)
    const [dataFound, setDataFound] = useState(false)

    const onSuccess = (_data) => {
        isLiveEnded.current = false
        firstCheck.current = false
        setDataFound(true)
    }

    const onError = (_err) => {
        isLiveEnded.current = true
        firstCheck.current = false
        setDataFound(false)
    }

    const { data: liveAuctionDetails, refetch, isLoading } = useFetch("dashBoardData", "/bid-leads/broker-live-auction", onSuccess, onError, 5000, true)


    const doAfterCompletion = () => {
        isLiveEnded.current = true
        firstCheck.current = false
        client.setQueriesData(["dashBoardData"], [])
        setDataFound(false)
        setShowNote(false)
    }

    const [hour, setHour] = useState(0)

    const hoursRenderer = ({ hours }) => {
        setHour(hours)
        return (
            hours
        )
    }

    const minutesRenderer = ({ minutes }) => {
        if (minutes <= 4) {
            dispatch(setShowNote(true))
        } else {
            dispatch(setShowNote(false))
        }
        return (
            minutes
        )
    }

    const secondsRenderer = ({ seconds }) => {
        return (
            seconds
        )
    }

    const isDataFound = () => {
        return dataFound && liveAuctionDetails !== "" ? true : false
    }

    if (isLoading) {
        return <>
            {firstCheck.current ? <Loading />
                :
                <LiveAuctionHistory isLiveEnded={isLiveEnded.current} />}
        </>
    }

    return (
        <>
            <ViewOnlyHeader />
            <ViewOnlyWrap>
                {isDataFound() ? (
                    isLoading ? isLiveEnded.current ? <NoAuctionRunning /> : <Loading /> :
                        <Container className='p-0'>
                            <div className="pb-1 pt-2">
                                <Row>
                                    <Col xs={12} sm={12} className='results-col-right'>
                                        {
                                            isDataFound() && (
                                                <div className="d-flex flex-column flex-sm-row justify-content-between align-items-center mx-1 mb-2 ">

                                                    <div className="live-auction-heading">
                                                        Live Auction
                                                    </div>

                                                    <div>
                                                        <div className="time-remaining">Time Remaining</div>
                                                        <p className="bid-credit">
                                                            <Row>
                                                                {
                                                                    hour !== 0 && (
                                                                        <Col className="count-down-col" xs={5} sm={5} md={6}>
                                                                            <p className="minutes border rounded text-center box position-relative">
                                                                                <Countdown key={new Date(liveAuctionDetails?.bid_end_time)}
                                                                                    date={new Date(liveAuctionDetails?.bid_end_time)} renderer={hoursRenderer} onComplete={doAfterCompletion} />
                                                                                <span className="min">hour</span>
                                                                                <span className="semi-colon">:</span>
                                                                            </p>
                                                                        </Col>
                                                                    )
                                                                }

                                                                <Col className="count-down-col" xs={5} sm={5} md={6}>
                                                                    <p className="minutes border rounded text-center box position-relative">
                                                                        <Countdown key={new Date(liveAuctionDetails?.bid_end_time)}
                                                                            date={new Date(liveAuctionDetails?.bid_end_time)} renderer={minutesRenderer} onComplete={doAfterCompletion} />
                                                                        <span className="min">min</span>
                                                                        <span className="semi-colon">:</span>
                                                                    </p>
                                                                </Col>

                                                                <Col className="count-down-col" xs={5} sm={5} md={6}>
                                                                    <p className="seconds border rounded text-center box">
                                                                        <Countdown key={new Date(liveAuctionDetails?.bid_end_time)}
                                                                            date={new Date(liveAuctionDetails?.bid_end_time)} renderer={secondsRenderer} />
                                                                        <span className="sec">sec</span>
                                                                    </p>
                                                                </Col>
                                                            </Row>

                                                        </p>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    </Col>
                                </Row>
                            </div>
                            {liveAuctionDetails?.lead?.map((details) => {
                                return (<UICard viewOnly={true} card={details} refetch={refetch} key={details.id} bidEndTime={liveAuctionDetails?.bid_end_time} filteredCardDetails={liveAuctionDetails?.lead} />
                                )
                            })}
                        </Container>
                )
                    :
                    <LiveAuctionHistory isLiveEnded={isLiveEnded.current} />}

            </ViewOnlyWrap>
        </>
    );
}

export default React.memo(Viewonly)

const ViewOnlyHeader = () => {
    return (
        <HeaderWrap>
            <nav className="navbar navbar-expand-md navbar-light bg-white shadow position-fixed w-100 z-index-navbar">
                <div className="container-fluid pe-0">
                    <div className="navbar-item">
                        <div className="d-inline-block text-truncate">
                            <button className="btn">
                                <img className="bid-logo" src={BidLead} alt="logo" />
                            </button>
                        </div>
                    </div>

                </div>
            </nav>

            <div className="five-min-timer-note">{viewOnlyNote}</div>
        </HeaderWrap>
    )
}